import {SkilsItem} from "./SkilsItem";
import {useTranslation} from "react-i18next";


function Service() {
    const [text, i18n] = useTranslation("global")

    class ServiceObj {
        name: string
        logo: string
        summary: String

        constructor(name: string, logo: string, summary: string) {
            this.name = name;
            this.logo = logo;
            this.summary = summary
        }
    }


    /*const skilsItems: ServiceObj[] = [

        new ServiceObj(text("service.db_design"), "/db.png", text("service.db_design_resume")),

        new ServiceObj(text("service.app_development"), "/mobile_app.png", text("service.app_development_resume")),

        new ServiceObj(text("service.api_creation"), "/api.png", text("service.api_creation_resume")),

    ]*/
    const skilsItems: ServiceObj[] = [

        new ServiceObj(text("service.db_design"), "/db.png", text("service.ae_db_design")),

        new ServiceObj(text("service.app_development"), "/mobile_app.png", text("service.ae_app_development")),

        new ServiceObj(text("service.api_creation"), "/api.png", text("service.ae_api_creation")),

    ]


    return (
        <div id="service" className="bg-main flex flex-col items-center text-white  main-x-padding">
            <h4 className="mx-auto font-roboto-slabr mt-12 mb-3 font-bold text-3xl">{text("header.areas_services")}</h4>


            {
                <div className="flex flex-wrap   max-w-6xl  gap-2 justify-center ">
                    {skilsItems.map(item => (
                        <div key={item.name} className=" w-72 flex items-center p-3 card flex-col  md:w-4/12 lg:w-3/12">
                            <img className={"w-5  h-5"} src={item.logo} alt={item.name}/>
                            <h5 className="   card-title text-center">{item.name}</h5>
                            <ul className={"list-disc text-left ps-3 "} >
                                {item.summary.split("ç/").map((st:string)=> (
                                    <li>{st}</li>
                                ) )}
                            </ul>

                        </div>
                    ))}
                </div>
            }
            {
               /* <div className="flex flex-wrap   max-w-6xl  gap-2 justify-center ">
                    {skilsItems.map(item => (
                        <div key={item.name} className=" w-72 flex items-center p-3 card flex-col  md:w-4/12 lg:w-3/12">
                            <img className={"w-5  h-5"} src={item.logo} alt={item.name}/>
                            <h5 className="   card-title text-center">{item.name}</h5>
                            <ul></ul>
                            <h6 className="font-roboto-slab text-gray-300 text-left ">{item.summary}</h6>
                        </div>
                    ))}
                </div>*/
            }


        </div>
    )
}

export {
    Service
}