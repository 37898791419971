function SocialNetwork({grow}:{grow:string}) {
    return <div className={ grow}>
        <div className="social bg flex  gap-6 justify-center  ">
            <div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/benderson-phanor-aab016236/"> <i className="fa-brands fa-linkedin icon-red-social "></i> </a>
            </div>
            <div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/sonderben"> <i className="fa-brands fa-github icon-red-social "></i> </a>
            </div>
            {/*<div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://es.quora.com/profile/Benderson-Phanor"> <i className="fa-brands fa-quora"></i> </a>
            </div>*/}
            <div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Sonderben"> <i className="fa-brands icon-red-social fa-facebook "></i> </a>
            </div>
            <div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sonderben"> <i className="fa-brands fa-x-twitter icon-red-social"></i> </a>
            </div>
            {/*<div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sonderben"> <i className="fa-brands fa-x-twitter icon-red-social"></i> </a>
            </div>

            <div className="polygone">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/sonderben/"> <i className="fa-brands icon-red-social fa-instagram "></i> </a>
            </div>*/}
        </div>
    </div>;
}

export {
    SocialNetwork
}