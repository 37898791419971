import {useTranslation} from "react-i18next";


function Projects (){
    const  [text,i18n] = useTranslation("global")

    class ServiceObj {
        name:string
        image:string
        summary:string
        //gitHub:string
        technology:string
        ressource:Resource
        constructor( name:string,image:string,summary:string,technology:string,ressource:Resource) {
            this.name = name;
            this.image = image;
            this.summary = summary
            this.technology = technology
            this.ressource = ressource
            //this.gitHub = gitHub
        }
    }






    const skilsItems:ServiceObj[] = [
        new ServiceObj(
            "Church kit",
            "/bible.png",
            text("project.ck"),
            text("project.ck_t"),
            new Resource("https://github.com/sonderben/Church_kit","","","https://t.me/sdbalpha/2/6")
        ),

        new ServiceObj(
            "Magemoney",
            "/magemoney.jpg"
            ,text("project.mm"),
            text("project.mm_t"),
            new Resource("https://github.com/sonderben/MageMoney","","","")
        ),

        //new ServiceObj("Api-Video","/apivideo.png",text("project.av"),new Resource("","","","") ),
        new ServiceObj(
            "Trust",
            "/trust_pay.png",
            text("project.tr"),
            text("project.tr_t"),
            new Resource("https://github.com/sonderben/Trust","","","") ),
    ]

    return(
        <div id="project" className="bg-main flex flex-col items-center text-white  main-x-padding">
            <h4 className="mx-auto font-roboto-slab mt-12 mb-3 font-bold text-3xl">{text("header.projects")}</h4>

                {
                    <div className="flex max-w-6xl w-4/5  flex-wrap gap-2 justify-center ">
                        {skilsItems.map(item => (
                            //<CardFlip title={item.name} description={item.summary} img={item.image} gitHub={item.gitHub} />
                            <Card title={item.name} description={item.summary} img={item.image} ressource={item.ressource} tech={item.technology}/>
                        ))}
                    </div>
                }




        </div>
    )
}

class Resource{
    gt:string
    ps:string
    yt:string
    apk:string

    constructor(gt: string="", ps: string="", yt: string="", apk: string="") {
        this.gt = gt;
        this.ps = ps;
        if (yt)
            this.yt = "https://www.youtube.com/watch?v="+yt;
        else
            this.yt = yt
        this.apk = apk;
    }
}









const Card = ({ title, description, tech,img, ressource }: { title: string; description: string; tech:string; img: string; ressource: Resource }) => {
    return (

        <div className=" card flex flex-col items-center w-72 md:w-3/12 h-72 md:h-3/12">
            <h2 className="card-title">{title}</h2>
            <div className=" flip-card">

                <div className="flip-card-inner">
                    <div className="flip-card-front card-image ">
                        <img className="responsive-img-proyect" src={img} alt={title} />
                    </div>
                    <div className="flip-card-back bg-main">

                        <p className="text-left text-sm">
                            {description}
                        </p>

                        {/*<ul className="list-disc ps-10 pt-2 grid grid-cols-2 gap-2">
                            {tech.split("/").map((t: string) => (
                                <li key={t} className="text-left font-thin text-xs">{t}</li>
                            ))}
                        </ul>*/}

                        <div className="px-2 flex items-center  gap-4 w-full p-3 ">
                            {ressource.gt && <a href={ressource.gt} target="_blank" rel="noopener noreferrer"> <img className="w-5 float-left" src={"/github.png"}  alt={title}/> </a>}
                            {ressource.ps && <a href={ressource.ps} target="_blank" rel="noopener noreferrer"> <img className="w-5 float-left" src={"/playstore.png"} alt={title}/> </a>}
                            {ressource.yt && <a href={ressource.yt} target="_blank" rel="noopener noreferrer"> <img className="w-5 float-left" src={"/youtube.png"} alt={title}/> </a>}
                            {ressource.apk && <a href={ressource.apk} target="_blank" rel="noopener noreferrer"> <img className="w-5 float-left" src={"/apk.png"} alt={title}/> </a>}
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
};



export {
    Projects,
   Card
}