import {SocialNetwork} from "./SocialNetwork";

function Footer(){
    const date: Date = new Date();
    const year: number = date.getFullYear();

    return(
        <>
            <div className="h-80 bg-main flex items-center gap-5 justify-center  pt-5 flex-col ">
                < SocialNetwork grow={"grow-p-0"}/>
                <h5 className="text-white"> © 2023 {year > 2023 && ` - ${year}`} Benderson Phanor</h5>
            </div>
        </>
    );
}

export {
    Footer
}