
import man from'../assets/man.png'
import {useTranslation} from "react-i18next";
function About(){
    const  [text,i18n] = useTranslation("global")
    return(
        <div className="flex w-full main-green justify-center">
            <div id="about" className="mx-auto max-w-6xl  flex  flex-col text-white  main-x-padding ">
                <h4 className="mx-auto  font-roboto-slab mt-12 font-bold text-3xl"> {text("header.about")} </h4>

                <div className="inline">



                    <p className="font-roboto-slab text-gray-300" >  {text("about.resume")} </p>
                    <br/>
                    <p className="font-roboto-slab text-gray-300" >  {text("about.resume2")}</p>
                    <br/>
                    {/*<p className="font-roboto-slab text-gray-300" >  {text("about.resume3")}</p>
                    <br/>*/}
                    <p className="font-roboto-slab text-gray-300" >  {text("about.resume4")}</p>
                    <br/>
                    <p className="font-roboto-slab text-gray-300" >  {text("about.resume5")}</p>

                </div>
            </div>
        </div>
    )
}
export {
    About
}