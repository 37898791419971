import React, {useRef} from 'react';
/*import "./scroll"*/
import './App.css';
import {Menu} from "./components/Menu";
import {Home} from "./components/Home";
import {About} from "./components/About";
import {Service} from "./components/Service";
import {Projects} from "./components/Projects";
import {Skils} from "./components/Skils";
import {Contact} from "./components/Contact";
import {Footer} from "./components/Footer";
import ButtonI18N from "./components/ButtonI18N";



function App() {
    useRef();
    return (
        <div className="App bg-main ">
        <header><Menu/></header>

            <Home />
            <About  />

            <Skils/>
            <Service/>
            <Projects/>
            <Contact/>
            <Footer/>
            <ButtonI18N/>

      </div>
  );
}

export default App;
