import profile from "../assets/ben.png"
import {SocialNetwork} from "./SocialNetwork";
import {useTranslation} from "react-i18next";


function Home() {
    const  [text,i18n] = useTranslation("global")
    return(
        <div id="home" className=" mx-auto max-w-6xl flex flex-col  justify-around item /*bg-amber-500*/ pt-12 h-screen md:pt-20">
            <div className="{/*bg-amber-950*/} gap-10 flex flex-col custom-landscape md:flex-row md:gap-0 md:justify-around">
                <div className={" container-img-size flex justify-center items-center bg_img_me self-center"}>
                    <img className=" rounded-b-lg     " src={profile} alt="profile"/>
                </div>

                <div className="font-roboto-slab ms-5 md:ms-0 md:self-center">
                    <h4 className="text-white text-xl sm:text-2xl font-bold  lg:text-4xl "> {text("home.hi")} </h4>
                    <h3 className="text-main-color  font-bold text-2xl sm:text-4xl lg:text-5xl ">Benderson Phanor</h3>
                    <h3 className=" text-gray-300 text-base sm:text-xl lg:text-2xl">{text("home.cse")} </h3>
                    <h3 className={"text-gray-400 text-xs lg:text-xl"}>{text("home.ret")}</h3>
                </div>
            </div>
            <div className="/*bg-main-color*/">
                <SocialNetwork grow={"grow-p-2"}/>
            </div>
        </div>
    )
}



export {Home}
