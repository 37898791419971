import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import  resumepdf from "../assets/resume.pdf";




function Menu(){

    const  [text,i18n] = useTranslation("global")

    const hamburger = useRef<HTMLDivElement>(null);
    const navMenu = useRef<HTMLUListElement>(null);

    const [linkSelected,setLinkSelected] = useState("home")

    let altSection = "home";


    function getVisibleSection() {
        const sections = ["home", "about", "skills", "service", "project", "contact"];

        for (const section of sections) {
            const element = document.getElementById(section);
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top >= 0 /*&& rect.bottom <= window.innerHeight*/) {
                    altSection = section;
                    return section;
                }
            }
        }
        return altSection; // Fallback to "home" if no section is fully visible
    }

    // useEffect to update the visibleSection state on scroll
    useEffect(() => {
        function handleScroll() {
            setLinkSelected( getVisibleSection );
        }

        // Attach the event listener on mount
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    class MenuItem{
         name:string;
         href:string
         constructor(name:string,href?:string) {
             this.name = name;
             if (href){
                 this.href = href;
             }else {
                 this.href = name.toLowerCase();
             }

         }
    }

    const menuItem = [
                                new MenuItem( text("header.about"),"about" ),
                                new MenuItem( text("header.skills"),"skills" ),
                                new MenuItem( text("header.services"),"service" ),
                                new MenuItem( text("header.projects"),"project" ),
                                new MenuItem( text("header.contact"),"contact" ),
                                //new MenuItem( text("header.resume"),"resume" ),
    ]
    const menuItemsDom = menuItem.map(item =>
        (
            <li key={item.href} className="nav-item py-1 font-roboto-slab ">
                <a href={item.href} onClick={ (event)=> handleAElement(event,item.href) } className={`nav-link ${linkSelected === item.href ? 'link-selected' : ''}`}>
                    {item.name}
                </a>
            </li>
        )
    )

    function handleHamburgerClick(){
        if (hamburger.current && navMenu.current){
            hamburger.current.classList.toggle("active")
            navMenu.current.classList.toggle("active")
        }
    }
    function handleAElement(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,id:string){
        event.preventDefault();
        const targetElement = document.getElementById(id);
        const headerOffset = 50; // para cualquier encabezado fijo que pueda estar presente
        const elementPosition = targetElement?.getBoundingClientRect().top;
        if (elementPosition){
            const offsetPosition = elementPosition - headerOffset;

            hamburger.current?.classList.remove("active");
            navMenu.current?.classList.remove("active");


            window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
            });

            setLinkSelected(id);
        }
    }
    return (
        <nav className="bg-zinc-900 z-50 h-12   px-2 fixed w-full flex flex-col gap-5 items-center md:h-20 md:flex-row md:justify-between">


            <div className="flex items-center b justify-between h-full w-full">
                <a onClick={ (event)=>handleAElement(event,"home") } href="#home" className=" p-1 text-white font-bold w-auto text-2xl  py-2 font-roboto-slab ">   Benderson Phanor</a>
                <div onClick={handleHamburgerClick} className=" hamburger  flex flex-col justify-around h-6 md:h-8 md:hidden" ref={hamburger}>
                    <span className="bar w-8 sm:w-10 h-0.5 md:h-1  bg-white"> </span>
                    <span className="bar w-8 sm:w-10 h-0.5 md:h-1 bg-white"> </span>
                    <span className="bar w-8 sm:w-10 h-0.5 md:h-1 bg-white"> </span>
                </div>
            </div>


            <ul  className="bg-zinc-900 text-xl nav-menu md:flex md:flex-row gap-6 md:static md:justify-end md:gap-3 lg:gap-10 " ref={navMenu}>


                {
                    menuItemsDom
                }

                <li id="resume" className=" resume  font-bold rounded-sm p-1">
                    <a target="_blank" href={resumepdf} className="nav-link " rel="noreferrer">{ text("header.resume") }</a>
                </li>
            </ul>


        </nav>
    )
}


export {
    Menu
}