import {useTranslation} from "react-i18next";


function Skils (){
    const  [text,i18n] = useTranslation("global")
    class SkilsObj {
        name:string
        logo:string
        constructor( name:string,logo:string) {
            this.name = name;
            this.logo = logo;
        }
    }




    const skilsItems = [
        new SkilsObj("Java","/java.png"),
        new SkilsObj("Swift","/swift.png"),
        new SkilsObj("Spring","/spring.png"),
        new SkilsObj("Firebase","/firebase.png"),
        new SkilsObj("aws","/aws.png"),
        new SkilsObj("Posgresql","/postgresql-icon.png"),
        new SkilsObj("Elasticsearch","/elasticsearch.png"),
        new SkilsObj("Html/Css/Js","/frontend.png"),
        new SkilsObj("ReactJS","/react.png")

    ]

    return(
        <div id="skills" className="main-green items-center flex flex-col  text-white  main-x-padding">
            <h4 className="mx-auto font-roboto-slab mt-12 font-bold mb-3 text-3xl">{text("header.skills")}</h4>


                <div className=" max-w-6xl flex flex-wrap gap-2 justify-center  ">

                    {skilsItems.map(item => (
                        <div key={item.name} className="flex flex-col  items-center card w-28 min-w-fit py-1 px-2 md:w-44 md:py-5">
                            <img className={"w-8 h-8 md:w-12 md:h-12"} alt={item.name} src={item.logo} />
                            <h5 className="font-roboto-slab text-gray-300 text-xl">{item.name}</h5>
                        </div>
                    ))}

                </div>




        </div>
    )
}

export {
    Skils
}