import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

export default function ButtonI18N() {
    const [t,i18n] = useTranslation("global")
    const [lngSelectorVisible,setLngSelectorVisible] = useState(false)

    useEffect(() => {

    }, [i18n.language]);
    function changeLanguage(lng:string){
        i18n.changeLanguage(lng).then(r => {
            document.documentElement.lang = lng;
            localStorage.setItem("CURRENT_LNG",lng)
            setLngSelectorVisible(false);
        });

        //
    }

    return <div className=" i18n">
        {/*<h3 className="self-start mx-1"> language</h3>*/}

        <div className={"i18n-button-container " + (lngSelectorVisible ? "visible w-36 " : " w-8 invisible bg-amber-500")}>
            <button className={"button-i18n p-2"} onClick={ ()=>changeLanguage("en") } >English</button>
            <button className={"button-i18n p-2"}  onClick={ ()=>changeLanguage("es") } >Spanish</button>
            <button className={"button-i18n p-2 " } onClick={ ()=>changeLanguage("fr") } >French</button>
        </div>


        <div className="button-choose-i18n">
            <button onClick={ () => setLngSelectorVisible( !lngSelectorVisible ) } >
                <i className={ !lngSelectorVisible ? "fa-solid fa-globe  " : "fa-solid fa-circle-xmark"}></i>
            </button>
        </div>
    </div>
}


