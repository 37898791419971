import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next"
import i18next from "i18next";
import gloabal_en from "./translations/en/global.json"
import gloabal_es from "./translations/es/global.json"
import gloabal_fr from "./translations/fr/global.json"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const language = ()=>{
    if (localStorage.getItem("CURRENT_LNG")){
        return localStorage.getItem("CURRENT_LNG")
    }
    /*var lng = navigator.language
    console.log(navigator.languages)
    lng = lng.split("-")[0].toLowerCase()
    if (lng === "en" || lng === "es" || lng === "fr" ){
        return lng
    }*/
    return"en"
}


i18next.init({
    interpolation: {escapeValue: false},
    lng: language()
    ,
    resources: {
        en: {
            global: gloabal_en
        },
        es: {
            global: gloabal_es
        },
        fr: {
            global: gloabal_fr
        }
    }
})
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
