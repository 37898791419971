import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function Contact() {
    const [isLoading, setIsLoading] = useState(false);

    const [text, i18n] = useTranslation("global")
    const fullNameRef = useRef<HTMLInputElement>(null)
    const telephoneRef = useRef<HTMLInputElement>(null)
    const messageRef = useRef<HTMLTextAreaElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)

    const [fullNameError, setFullNameError] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)

    useEffect(() => emailjs.init("fz9EvfJabGUYLPoBv"), []);

    const clearField = () => {
        fullNameRef.current!.value = ""
        emailRef.current!.value = ""
        telephoneRef.current!.value = ""
        messageRef.current!.value = ""

        /*setFullNameError(false)
        setMessageError(false)
        setEmailError(false)*/

    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const serviceId = "sdbpha";
        const templateId = "template_0zg02vk";
        if (isFormValid()) {
            setIsLoading(true);
            try {

                const response = await emailjs.send(serviceId, templateId, {
                    from_name: fullNameRef.current!.value,
                    from_email:emailRef.current!.value,
                    from_tel:telephoneRef.current!.value,
                    message: messageRef.current!.value
                });

                if (response.status === 200) {
                    await Swal.fire({
                        //position: 'top-end',
                        icon: 'success',
                        title: text("contact.email_sent"),
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            popup: 'swal-text-selectable' // Aplica la clase que permite la selección de texto
                        }

                    });
                    clearField();
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: text("contact.email_not_sent"),
                        text: text("contact.email_not_sent_alternative"),
                        timer:60_000
                    });
                    //console.error('Error sending email:', response);
                }


            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    }


    const isEmailValid = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isFormValid = (): boolean => {
        var isValid = true;
        const numWord = 6

        const fullName = fullNameRef.current?.value.trim() || "";
        const email: string = emailRef.current?.value.trim() || "";
        const telephone = telephoneRef.current?.value.trim() || "";
        const message = messageRef.current?.value.trim() || "";

        if (fullName.length < 6 && fullName.split(" ").length < 2) {
            isValid = false;
            setFullNameError(true)
        } else {
            setFullNameError(false)
        }
        if (!isEmailValid(email)) {
            setEmailError(true)
            isValid = false;
        } else {
            setEmailError(false)
        }

        if (message.length < 6 || message.split(" ").length < numWord) {
            setMessageError(true)
            isValid = false;
        } else {
            setMessageError(false)
        }


        return isValid
    };


    return (
        <div id="contact" className="bg-main flex flex-col items-center text-white  main-x-padding">
            <h4 className="mx-auto font-roboto-slab mt-12 mb-3 font-bold text-3xl">{text("header.contact")}</h4>

            {/*style={{ userSelect: 'none' }*/}
            <form style={ { cursor: isLoading ? 'wait' : 'default',
                userSelect: 'none'} }
                  className="flex w-11/12 max-w-6xl rounded-xl pb-5 flex-col items-center card md:flex-row md:w-8/12 justify-center">

                {/*<div className="w-2/5 self-center flex flex-row items-center  md:flex-col md:self-start ">
                    <div className={"hidden md:block"}>
                        <img className="bg-gray-700 min-w-full" src={"/qr_vcard.png"}  alt={"qr code"}/>
                        <h4> {text("contact.scan_me")}</h4>
                    </div>
                    <div className="mt-10 hidden ">

                        <h1 className={"flex items-center"}>
                            <a href="mailto:sonderben@gmaicom"> <i className=" mr-2 fa-regular fa-envelope text-xl "></i> </a>
                            sonderben@gmail.com
                        </h1>
                        <h1 className={"flex items-center"}>

                            <a href="#"> <i className=" mr-2 fa-solid fa-phone text-xl "></i> </a>
                            +1(829 304 8654)
                        </h1>
                        <h1 className={"flex items-center whitespace-nowrap line overflow-x-hidden overflow-ellipsis"}>
                            <a href="#"> <i className=" mr-2 fa-solid fa-location-dot text-xl "></i> </a>
                            Av. mexico # 34. Santo Domingo, Republica Dominicana
                        </h1>
                    </div>
                </div>*/}

                <div className=" flex flex-col  items-center">

                    <div className="mb-1 flex flex-col mt-2 items-start  w-11/12">
                        <h5 className="py-1  text-left text-xl font-bold font-roboto-slab">{text("contact.title")}</h5>
                    </div>

                    <div className="mb-1 flex flex-col mt-2 items-start  w-11/12">
                        <label htmlFor="email" className="text-main-color">{text("contact.email")}</label>
                        <input ref={emailRef} className="formbold-form-input" type="email" name="email" id="email"
                               placeholder={text("contact.required")}/>
                        <label htmlFor="email"
                               className="text-orange-600"> {emailError ? text("contact.email_error") : ""} </label>
                    </div>
                    <div className="mb-1 flex flex-col mt-2 items-start  w-11/12">
                        <label htmlFor="full_name" className="text-main-color">{text("contact.full_name")}</label>
                        <input ref={fullNameRef} className="formbold-form-input" type="text" name="full_name"
                               id="full_name" placeholder={text("contact.required")}/>
                        <label htmlFor="email"
                               className="text-orange-600"> {fullNameError ? text("contact.full_name_error") : ""} </label>
                    </div>
                    <div className="mb-1 flex flex-col mt-2 items-start  w-11/12">
                        <label htmlFor="tel" className="text-main-color">{text("contact.tel")}</label>
                        <input ref={telephoneRef} className="formbold-form-input" type="tel" name="tel" id="tel"/>
                    </div>

                    <div className="mb-1 flex flex-col mt-2 items-start  w-11/12">
                        <label htmlFor="message" className="text-main-color">
                            {text("contact.message")}
                        </label>
                        <textarea
                            ref={messageRef}
                            rows={6}
                            name="message"
                            id="message"
                            placeholder={text("contact.required")}
                            className="formbold-form-input"
                        ></textarea>
                        <label htmlFor="email"
                               className="text-orange-600"> {messageError ? text("contact.message_error") : ""} </label>
                    </div>

                    <div>
                        <button disabled={isLoading} onClick={(event) => handleSubmit(event)}
                                className="formbold-btn ">{text("contact.submit")}</button>
                    </div>
                </div>

            </form>


        </div>
    )
}

/**/


function YourComponent() {
    const [isFormActive, setIsFormActive] = useState(false);

    const toggleForm = () => {
        setIsFormActive(!isFormActive);
    };

    return (
        <div className={`formbold-main-wrapper ${isFormActive ? 'active' : ''}`}>
            <div className="w-full">
                <div className={`formbold-form-wrapper ${isFormActive ? 'active' : ''}`}>
                    <div className="formbold-form-header">
                        <h3>Let's chat? - Online</h3>
                        <button onClick={toggleForm}>
                            {isFormActive ? (
                                <span>Close Chat</span>
                            ) : (
                                <span>Open Chat</span>
                            )}
                        </button>
                    </div>
                    <form
                        action="https://formbold.com/s/FORM_ID"
                        method="POST"
                        className="formbold-chatbox-form"
                    >
                        <div className="formbold-mb-5">
                            <label htmlFor="name" className="formbold-form-label">
                                Your Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Your Name"
                                className="formbold-form-input"
                            />
                        </div>

                        <div className="formbold-mb-5">
                            <label htmlFor="email" className="formbold-form-label">
                                Email Address
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@domain.com"
                                className="formbold-form-input"
                            />
                        </div>

                        <div className="formbold-mb-5">
                            <label htmlFor="message" className="formbold-form-label">
                                Message
                            </label>
                            <textarea
                                rows={6}
                                name="message"
                                id="message"
                                placeholder="Explain your queries"
                                className="formbold-form-input"
                            ></textarea>
                        </div>

                        <div>
                            <button className="formbold-btn w-full">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="formbold-action-buttons">
                    <button
                        className={`formbold-action-btn ${isFormActive ? 'active' : ''}`}
                        onClick={toggleForm}
                    >
                        {isFormActive ? (
                            <span>Minimize Chat</span>
                        ) : (
                            <span>Maximize Chat</span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default YourComponent;


export {
    Contact
}